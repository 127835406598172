import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { useEffect, useState, useMemo } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import PullToRefresh from "react-simple-pull-to-refresh";
import PageHeader from "../components/PageHeader";
import useAuth from "../hooks/useAuth";
import PastShow from "../models/PastShow";
import { useNavigate } from "react-router-dom";
import Config from "../models/Config";
import Cache from "../models/Cache";

function ShowResultsPage() {
  const user = useAuth();
  const navigate = useNavigate();
  const [pastShows, setPastShows] = useState<PastShow[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = `${Config.TAB_PREFIX}Previous Shows`;
  }, [])

  const getPastShows = useMemo(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetch(`${Config.SHEETS_URL}?key=${Config.SHEETS_KEY}`, {
          method: 'GET'
        });
        const jsonData = await data.json();
        const pastShowPages = jsonData.sheets.filter((sheet: any) => sheet.properties.title.includes('|'));
        const pastShows = pastShowPages.map((pastShow: PastShow) => new PastShow(pastShow)).filter((show: PastShow) => new Date(show.date) <= new Date());
        setPastShows(pastShows);
        Cache.ShowResultsPage.data = pastShows;
        Cache.ShowResultsPage.timestamp = Date.now();
      } catch (error) {
        // Handle error
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    return async () => {
      if (Date.now() - Cache.ShowResultsPage.timestamp < Config.CACHE_EXPIRATION_TIME) {
        setPastShows(Cache.ShowResultsPage.data);
      } else {
        fetchData();
      }
    };
  }, [user]);
  
  // useEffect hook to periodically invalidate cache after 15 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (Date.now() - Cache.ShowResultsPage.timestamp >= Config.CACHE_EXPIRATION_TIME) {
        Cache.ShowResultsPage.timestamp = 0; // Invalidate cache if expired
      }
    }, Config.CACHE_EXPIRATION_TIME);
  
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getPastShows();
  }, [getPastShows]);

  function goToShowResult(show: PastShow) {
    navigate(`/showResults/${encodeURIComponent(show.id)}`);
  }
  return (
    <LoadingOverlay active={isLoading} className="loading-overlay">
      <PullToRefresh onRefresh={getPastShows}>
        <>
          <Container
            maxWidth="sm"
            sx={{ marginBottom: "60px" }}
            className="page-header"
          >
            <PageHeader header="Show Results" />
            {pastShows.length === 0 && !isLoading && (
              <div className="center-text" key="no-content">
                <h2>No previous shows</h2>
              </div>
            )}
            <Stack spacing={2}>
              {pastShows &&
                pastShows.map((show) => {
                  return (
                    <>                      
                    <div
                      key={show.id}
                      className="show-results-list-row card-row pointer"
                      onClick={() => goToShowResult(show)}
                    >
                      <div className="show-results-list-title">
                        {show.title}
                      </div>
                      <div className="show-results-list-date">
                        {moment(show.date).format("MM/DD/YYYY")}
                      </div>
                    </div>
                    </>
                  );
                })}
            </Stack>
          </Container>
        </>
      </PullToRefresh>
    </LoadingOverlay>
  );
}
export default ShowResultsPage;
