import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import useAuth from "../hooks/useAuth";
import { PrivateRoutes } from "./PrivateRoutes";
import FeatureBits from "../models/FeatureBits";

export const AppRouter = () => {
  const user = useAuth();
  return (
    <BrowserRouter>
      <Routes>
        { FeatureBits.isAuthRequired ? (
          <>
          {user ? (
            <Route path="/*" element={<PrivateRoutes />} />
          ) : (
            <Route path="login" element={<LoginPage />} />
          )}
  
          <Route path="*" element={<Navigate to="/login" replace />} />
          </>
        ) : (<Route path="/*" element={<PrivateRoutes />} />)
        }
      </Routes>
    </BrowserRouter>
  );
};
