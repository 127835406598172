import { useEffect, useMemo, useState } from "react";
import Config from "../models/Config";
import useAuth from "../hooks/useAuth";
import Container from '@mui/material/Container';
import PageHeader from "../components/PageHeader";
import Stack from '@mui/material/Stack';
import LoadingOverlay from "react-loading-overlay-ts";
import Cache from "../models/Cache";
import { Arena } from "../models/Arenas";

export default function ArenasPage() {
  const user = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<Arena[]>([]);

  const getArenas = useMemo(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetch(`${Config.SHEETS_URL}/values/${encodeURIComponent("Arenas!A2:C")}?key=${Config.SHEETS_KEY}`, {
          method: 'GET'
        });
        const jsonData = await data.json();
        const arenas = jsonData.values.map((value: any) => new Arena(value));
        Cache.ArenasPage.data = arenas;
        Cache.ArenasPage.timestamp = Date.now();
        setResult(arenas);
      } catch (error) {
        // Handle error
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    return async () => {
      if (Date.now() - Cache.ArenasPage.timestamp < Config.CACHE_EXPIRATION_TIME) {
        setResult(Cache.ArenasPage.data);
      } else {
        fetchData();
      }
    };
  }, [user]);

  useEffect(() => {
    getArenas();
  }, [getArenas]);
  
  useEffect(() => {
    document.title = `${Config.TAB_PREFIX}Arenas`
  }, [])
  
  const openTab = (url: string) => {
   if (url) {
    window.open(url, "_blank", "noopener,noreferrer");
   }
  };
  return (
    <LoadingOverlay active={isLoading} className="loading-overlay">
      <Container maxWidth="sm" sx={{ marginBottom: '60px' }}>
        <PageHeader header="Arenas" />
        <Stack spacing={2} marginTop={2}>
         <div className={`grid-container ${result.length === 1 ? "single-column" : ""}`}>
            {result &&
              result.map((value, index) => (
                <div className="grid-item card-row" key={index}>
                  <img
                    className="pointer center img"
                    src={value.image}
                    alt={value.name}
                    onClick={() => openTab(value.url)}
                  />
                </div>
              ))}
          </div>
        </Stack>
      </Container>
    </LoadingOverlay>
  );
  }