import { Navigate, Route, Routes } from 'react-router-dom';
import SchedulePage from '../pages/SchedulePage';
import AppBar from '../components/AppBar';
import ShowResultsPage from '../pages/ShowResultsPage';
import ShowResultPage from '../pages/ShowResultPage';
import StandingsPage from '../pages/StandingsPage';
import SponsorsPage from '../pages/SponsorsPage';
import ArenasPage from '../pages/ArenasPage';

export const PrivateRoutes = () => {
  return (
    <>
      <AppBar />
      <Routes>
        <Route path="/schedule" element={<SchedulePage />} />
        <Route path="/showResults" element={<ShowResultsPage />} />
        <Route path="/showResults/:showId" element={<ShowResultPage />} />
        <Route path="/standings" element={<StandingsPage />} />
        <Route path="/sponsors" element={<SponsorsPage />} />
        <Route path="/arenas" element={<ArenasPage />} />
        {/* <Route path="/riderResults" element={<RiderResultPage />} /> */}
        <Route path="*" element={<Navigate to="/schedule" replace />} />
      </Routes>
    </>
  );
};
